.tableContainer {
	width: 100%;
	display: table;
}

.table {
	max-width: 80%;
}

.tr {
	/* display: table-row;˝ */
	padding-bottom: 1rem;
	padding-left: 1rem;
}

.tdLeft {
	display: table-cell;
	width: 40%;
}

.tdRight {
	display: table-cell;
	padding-left: 1rem;
}

.input {
	font-size: 1rem;
	padding: 0.5rem;
	background-color: transparent;
	border: none;
	color: #efefef;
	width: 100%;
}

.description {
	font-size: 0.8rem;
	text-align: left;
}

.buttonPanel {
	margin-top: 2rem;
	display: flex;
	justify-content: flex-end;
	padding-right: 10%;
	column-gap: 1rem;
}

@media screen and (max-width: 600px) {
	.tdLeft {
		display: block;
		width: 90%;
	}
	.tdRight {
		display: block;
		width: 90%;
		margin-top: 0.5rem;
	}
	.description {
		font-size: 0.5rem;
		text-align: left;
		margin-bottom: 1rem;
	}
	.buttonPanel {
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		padding-right: 0;
		column-gap: 1rem;
	}
}