@font-face {
    font-family: "body";
    src: url("./conthrax-sb.ttf");
}

.App {
    text-align: center;
    flex-grow: 1;
}

.title {
    flex-grow: 0;
    font-family: 'body';
}

.mainView {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    font-family: 'body';
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.status {
    font-size: x-small;
    margin: 1rem;
    font-family: 'body';
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 600px) {
    .status {
        font-size: x-small;
        margin: 0.5rem;
        font-family: 'body';
    }
    .mainView {
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        font-family: 'body';
    }
}