.button {
    padding: 2rem;
    font-size: xx-large;
    margin: 2rem;
}

.buttonBlockHomepage {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5rem;
}

.listContainer {
    overflow: scroll;
    height: 2.5rem;
    margin-bottom: 0.5rem;
}

.h4 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.list {
    font-size: x-small;
    margin: 1rem;
    font-family: 'body';
    text-align: center;
}

.lightInput {
    border-style: dotted;
    border-width: 1px;
    border-color: #61dcff;
    background-color: transparent;
    color: #61dcff;
}

.innerFrame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20rem;
}

.framePadding {
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .buttonBlockHomepage {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 0rem;
        row-gap: 3rem;
    }
    .framePadding {
        margin-bottom: 2rem;
    }
}