.h2 {
	font-size: 1.2rem;
	margin-bottom: 1rem;
}

.tableContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.label {
	padding: 1rem;
}

.h3 {
	margin-top: 3rem;
	margin-bottom: 1rem;
}

.xrc21TokenLabel {
	margin-bottom: 1rem;
	width: 25rem
}

.transferPanel {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 2rem;
	width: 25rem;
}

.smallButtonPanel {
	display: flex;
	justify-content: flex-end;
}

.inputWithinFrame {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.transferPanel {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		padding: 2rem;
		width: 21rem;
	}
	.xrc21TokenLabel {
		margin-bottom: 1rem;
		width: 21rem
	}
	.inputWithinFrame {
		margin-bottom: 1rem;
	}
}